<template>
  <div class="img">
    <MediaImage
      v-if="imgSrc"
      :asset="imgSrc"
      :alt="imgAlt"
      loading="lazy"
      class="img-fluid"
    />
  </div>
</template>

<script>
import MediaImage from '@/components/MediaImage.vue';

export default {
  name: 'ArticleHeroImage',
  components: { MediaImage },
  //---------------------------------------------------
  //
  //  Properties
  //
  //---------------------------------------------------
  props: {
    imgSrc: {
      type: String,
      default: null,
    },
    imgAlt: {
      type: String,
      default: null,
    },
  },
  //---------------------------------------------------
  //
  //  Data model
  //
  //---------------------------------------------------
  data() {
    return {};
  },
  //---------------------------------------------------
  //
  //  Computed Properties
  //
  //---------------------------------------------------
  computed: {
  },
  //---------------------------------------------------
  //
  //  Watch Properties
  //
  //---------------------------------------------------
  watch: {},
  //---------------------------------------------------
  //
  //  Filter Properties
  //
  //---------------------------------------------------
  // filters: {},
  //---------------------------------------------------
  //
  //  Validation Properties
  //
  //---------------------------------------------------
  // validations: {},
  //---------------------------------------------------
  //
  //  Vue Lifecycle
  //
  //---------------------------------------------------
  // beforeCreate() {},
  // created() {},
  // beforeMount() {},
  // render(h) { return h(); },
  // mounted() {},
  // beforeUpdate() {},
  // updated() {},
  // beforeDestroy() {},
  // destroyed() {},
  //---------------------------------------------------
  //
  //  Methods
  //
  //---------------------------------------------------
  methods: {
    //----------------------------------
    // Event Handlers
    //----------------------------------
  },
};
</script>

<style lang="scss" scoped>
@import '~scss/mixins';
@import '~scss/variables';

div.img {
  width: 100%;
  aspect-ratio: 16 / 8;
  margin-top: 80px;

  &:empty {
    aspect-ratio: initial;
  }

  .img-fluid {
    width: 100%;
    height: 100%;
    object-fit: cover;

    @include breakpoint('sm') {
      object-fit: cover;
    }
  }

  @include breakpoint('sm') {
    aspect-ratio: 16 / 8;
    margin-top: 32px;
  }
}
</style>
