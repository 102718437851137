import { render, staticRenderFns } from "./VideoPlayer.vue?vue&type=template&id=710a4236&scoped=true&"
import script from "./VideoPlayer.vue?vue&type=script&lang=js&"
export * from "./VideoPlayer.vue?vue&type=script&lang=js&"
import style0 from "./VideoPlayer.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./VideoPlayer.vue?vue&type=style&index=1&id=710a4236&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "710a4236",
  null
  
)

export default component.exports