import { render, staticRenderFns } from "./ArticleLead.vue?vue&type=template&id=fd4a5778&scoped=true&"
import script from "./ArticleLead.vue?vue&type=script&lang=js&"
export * from "./ArticleLead.vue?vue&type=script&lang=js&"
import style0 from "./ArticleLead.vue?vue&type=style&index=0&id=fd4a5778&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd4a5778",
  null
  
)

export default component.exports