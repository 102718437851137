var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"count-up-container"},[_c('div',{staticClass:"grid"},_vm._l((_vm.counterData),function(counter,i){return _c('CountUp',{key:("counter" + i),attrs:{"num-target":counter.value,"num-label":counter.title,"count-options":{
        useEasing: true,
        useGrouping: true,
        decimalPlaces: counter.decimals,
        separator: '\'',
        decimal: '.',
        prefix: (counter.prefix) ? counter.prefix : '',
        suffix: (counter.suffix) ? counter.suffix : '',
      }}})}),1)])}
var staticRenderFns = []

export { render, staticRenderFns }